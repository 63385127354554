import React from 'react';
const getKeyValue = Dict.getLabel
class DeviceInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { info = {} } = this.props;
    let platInfo;
    try {
      const { type, ptz_control, platform } = info.extJson.cameraInfo
      platInfo= type && type=="gb"? ptz_control : platform
      if((typeof info.accessType === 'string') && info.accessType.toLowerCase() === 'sdk' && platform === 1 ) {
        platInfo = 1;
      }
    } catch {

    }
    return (
      <div className="device-info-layout">
        <h3 className="part-title">设备信息</h3>
        <div className="content-layout">
          <div className="content-item">
            <div>设备类型:</div>
            <div>{getKeyValue('deviceType', info.deviceType)}</div>
          </div>
          <div className="content-item">
            <div>设备型号:</div>
            <div>{info.extJson.cameraInfo.type}</div>
          </div>
          <div className="content-item">
            <div>设备品牌:</div>
            <div>{(info.extJson.cameraInfo.brand)|| '未知'}</div>
          </div>
          <div className="content-item">
            <div>SN:</div>
            <div>{info.sn}</div>
          </div>
          <div className="content-item">
            <div>CID:</div>
            <div>{info.cid}</div>
          </div>
          <div className="content-item">
            <div>箱体号:</div>
            <div>{info.boxNum}</div>
          </div>
          <div className="content-item">
            <div>云台:</div>
            <div>{platInfo == '1' ? '是' : '否'}</div>
          </div>
          <div className="content-item">
            <div>视频储存周期:</div>
            <div>{info.extJson.cameraInfo.storage?info.extJson.cameraInfo.storage.video?info.extJson.cameraInfo.storage.video:7 :7}天</div>
          </div>
          <div className="content-item">
            <div>图片储存周期:</div>
            <div>{info.extJson.cameraInfo.storage?info.extJson.cameraInfo.storage.picture?info.extJson.cameraInfo.storage.picture:30:30}天</div>
          </div>
        </div>
      </div>
    );  
  }
}
export default DeviceInfo;