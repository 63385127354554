(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("deviceManagement", ["React", "antd", "mobxReact", "ReactRouterDOM", "_", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["deviceManagement"] = factory(require("React"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("_"), require("mobx"));
	else
		root["deviceManagement"] = factory(root["React"], root["antd"], root["mobxReact"], root["ReactRouterDOM"], root["_"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 